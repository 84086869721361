import React from "react"
import "./Footer.scss"

const Footer = (props) => {
  return (
    <div className="Footer">
      <div className="Copyright">
        <p> © Robert Beekelaar</p>
      </div>
    </div>
  )
}
export default Footer
