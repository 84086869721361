import React from "react"
import Container from "../Container/Container"


const App = () => {
  return (
    <div className="App">
      <Container />
    </div>
  )
}

export default App
